import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/error/not-found/not-found.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { TutorialsComponent } from './pages/tutorials/tutorials.component';
import { DocComponent } from './pages/tutorials/doc/doc.component';
import { RunningGroupProjectComponent } from './pages/tutorials/running-group-project/running-group-project.component';
import { PreprocessingGroupProjectComponent } from './pages/tutorials/preprocessing-group-project/preprocessing-group-project.component';
import { ProcessingGroupProjectComponent } from './pages/tutorials/processing-group-project/processing-group-project.component';
import { AboutComponent } from './pages/about/about.component';
import { M82ApgpComponent } from './pages/gallery/m82-apgp/m82-apgp.component';
import { M51DscComponent } from './pages/gallery/m51-dsc/m51-dsc.component';
import { M31Component } from './pages/gallery/m31/m31.component';
import { GKPerseiComponent } from './pages/gallery/gkpersei/gkpersei.component';
import { CasaDscComponent } from './pages/gallery/casa-dsc/casa-dsc.component';
import { BlogComponent } from './pages/blog/blog.component';
import { PrintsComponent } from './pages/prints/prints.component';
import { Snr156Component } from './pages/gallery/snr156/snr156.component';
import { M106Component } from './pages/gallery/m106/m106.component';
import { ChainComponent } from './pages/gallery/chain/chain.component';
import { M51v2Component } from './pages/gallery/m51v2/m51v2.component';
import { DeerlickComponent } from './pages/gallery/deerlick/deerlick.component';
import { Snr195Component } from './pages/gallery/snr195/snr195.component';



const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'gallery', component: GalleryComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'prints', component: PrintsComponent},
  {path: 'tutorials', component: TutorialsComponent},
  {path: 'tutorials/doc', component: DocComponent},
  {path: 'tutorials/project', component: RunningGroupProjectComponent},
  {path: 'tutorials/preprocessing', component: PreprocessingGroupProjectComponent},
  {path: 'tutorials/processing', component: ProcessingGroupProjectComponent},
  {path: 'about', component: AboutComponent},
  {path: 'm82', component: M82ApgpComponent},
  {path: 'm82/:version', component: M82ApgpComponent},
  {path: 'm51', component: M51DscComponent},
  {path: 'm31', component: M31Component},
  {path: 'm31/:version', component: M31Component},
  {path: 'gkpersei', component: GKPerseiComponent},
  {path: 'gkpersei/:version', component: GKPerseiComponent},
  {path: 'casa', component: CasaDscComponent},
  {path: 'casa/:version', component: CasaDscComponent},
  {path: 'snr156',   redirectTo: '/snr156/steeve', pathMatch: 'full' },
  {path: 'snr156/:version', component: Snr156Component},
  {path: 'm106', component: M106Component},
  {path: 'm106/:version', component: M106Component},
  {path: 'chain', component: ChainComponent},
  {path: 'chain/:version', component: ChainComponent},
  {path: 'm51v2', component: M51v2Component},
  {path: 'm51v2/:version', component: M51v2Component},
  {path: 'deerlick', component: DeerlickComponent},
  {path: 'deerlick/:version', component: DeerlickComponent},
  {path: 'snr195', component: Snr195Component},
  {path: 'geminisnr1', component: Snr195Component},
  
  {path: '404', component: NotFoundComponent},
  {path: '**', pathMatch: 'full', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
