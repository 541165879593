<div class="container-fluid bg-dark">
  <div class="container text-light">
    <div class="card bg-dark">
      <img class="card-img translate-in-from-top" src="assets/img/SNR195-web.jpg" alt="">
      <div class="card-img-overlay d-flex align-items-start">
        <div class="card-text d-none d-md-block ms-3">
          <h1 class="translate-in-from-left"><b>Deep Sky
              Collective</b>
          </h1>
          <h4 class="col-12 col-lg-8 translate-in-from-right">Collaborative imaging: A new era.</h4>
        </div>
      </div>
      <div class="card-img-overlay d-flex align-items-end">
        <div class="card-text d-none d-md-block ms-3">
          <h4 class="col-12 col-lg-10 offset-md-1 text-center p-lg-4 translate-in-from-bottom">We're striving to advance amateur astrophotography. We're convinced that working together on imaging is an essential step in exploring a host of new possibilities, solidifying the role of amateur astrophotography in scientific research. </h4>
        </div>
      </div>
    </div>
    <div class="row d-md-none">
      <div class="col">
        <h3 class="translate-in-from-left text-center"><b>Deep Sky Collective</b></h3>
        <p class="translate-in-from-right text-center">Collaborative imaging: A new era</p>
        <p class="translate-in-from-bottom text-center">We're striving to advance amateur astrophotography. We're convinced that working together on imaging is an essential step in exploring a host of new possibilities, solidifying the role of amateur astrophotography in scientific research. </p>
      </div>
    </div>
  </div>
</div>