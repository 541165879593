import { Component } from '@angular/core';
import jsonTarget from '../../../../locale/data/snr195.json';
import { faShuttleSpace } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-snr195',
  templateUrl: './snr195.component.html',
  styleUrls: ['./snr195.component.sass']
})
export class Snr195Component {
  target: any = jsonTarget;
  faShuttleSpace = faShuttleSpace;
}
