<app-astro-photo    [astrophoto]="target"
                    [imageTileSources]="['assets/astro-img/Gemini SNR1_v9.dzi']"
                    [annotationsTilesUrl]="'assets/astro-img/Gemini_SNR1_starless_v9_Annotations/'"
                    [imageWidth]="6208"
                    [imageHeight]="4141" [imageScale]="1.10"
                    [watermark]="false"
                    [filterExposures]="[{ filter: 'R', brand: '', exposures: [{ count: 248, total: 81900 }] },
                                        { filter: 'G', brand: '', exposures: [{ count: 219, total: 71400 }] },
                                        { filter: 'B', brand: '', exposures: [{ count: 270, total: 90000 }] },
                                        { filter: 'Ha', brand: '', exposures: [{ count: 348, total: 501000 }] },
                                        { filter: 'Oiii', brand: '', exposures: [{ count: 271, total: 390000 }]}]"
                    [nights]="{ count: 20, start: '2025-01-18', end: '2025-02-06' }"
                    [equipment]="{  mounts: [],
                                    telescopes: ['Askar 130PHQ', 'Askar 151PHQ', 'Askar FRA500', 'Sky-Watcher Esprit 150ED', 'StellaMira 90mm ED Triplet', 'Stellarvue SVX080T', 'Takahashi TOA-150'],
                                    cameras: ['Moravian Instruments C3-61000EC Pro', 'OGMA AP26MC', 'Player One Poseidon-M Pro', 'QHYCCD QHY600PH M', 'ZWO ASI2600MM Pro', 'ZWO ASI6200MM Pro'] }">
</app-astro-photo>